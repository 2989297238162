<template>
    <div class="table-icon" @click="switchIconHandler">
        <ul :class="{ table: isTable, card: !isTable }">
            <li class="square"></li>
            <li ref="liEl" class="line"></li>
            <li class="square"></li>
            <li class="line"></li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isTable: false,
            statusList: []
        };
    },
    mounted() {
        this.$refs.liEl.addEventListener('transitionend', this.transitionEvent, false);
    },
    beforeDestroy() {
        this.$refs.liEl.removeEventListener('transitionend', this.transitionEvent, false);
    },
    methods: {
        // 切换
        switchIconHandler() {
            this.isTable = !this.isTable;
        },
        // 过渡事件,防抖
        transitionEvent(event) {
            const { propertyName } = event;
            if (propertyName !== 'width') return;
            const debounceFn = this.$utils.debounce(() => {
                this.$emit('change', { isTable: this.isTable });
            }, 200);
            return debounceFn();
        }
    }
};
</script>

<style lang="scss" scoped>
    $border: 1px solid #ccc;
    .table-icon{
        position: relative;
        width: 25px;
        height: 25px;
        cursor: pointer;
        ul{
            position: absolute;
            z-index: 111;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translate3d(0, 0, 0);
            li{
                float: left;
                margin-bottom: 5px;
                transition: all 0.2s ease;
            }
        }
        .table{
            transform: rotate(0);
            transition: transform 0.2s ease;
            li:nth-child(odd) {
                width: 5px;
                height: 5px;
                border: $border
            }
            li:nth-child(even) {
                width: 15px;
                height: 5px;
                margin-left: 2px;
                border-top: $border;
                border-bottom: $border;
                border-left: 0 solid #ccc;;
                border-right: 0 solid #ccc;;
            }
        }

        .card{
            transform: rotate(90deg);
            transition: transform 0.2s ease;
            li {
                width: 8px;
                height: 8px;
                border: $border
            }
            li:nth-child(even){
                margin-left: 2px;
            }
        }
    }
</style>
