<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-09 11:01:07
 * @Description:
 -->
<template>
    <div class="my-comic page-container flex-column">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="center" :model="searchData" inline @keyup.enter.native.prevent="searchHandle">
            <el-form-item prop="queryType" label="审核状态:">
                <el-select v-model="searchData.queryType" style="width:100px" placeholder="选择状态" @change="searchHandle">
                    <el-option v-for="(item,index) in auditType" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="showStatus" label="显示状态:">
                <el-select v-model="searchData.showStatus" style="width:100px" placeholder="选择状态">
                    <el-option v-for="(item,index) in showTypes" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="queryText" label="关键词:">
                <el-input v-model="searchData.queryText" placeholder="输入漫画或作者名称" clearable class="search-input" @keyup.enter.native.prevent="searchHandle"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <div class="my-comic-operation">
            <div class="flex-row-between">
                <div class="flex-row-center">
                    <el-button type="primary" size="mini" @click="addComicHandle">新增漫画</el-button>
                    <el-button size="mini" @click="displayComicHandle(1)">上架</el-button>
                    <el-button size="mini" @click="displayComicHandle(0)">下架</el-button>
                    <el-tabs class="ml20 f14" v-model="currentTabId" @tab-click="tabChangeHandler">
                        <template v-for="tab in auditType">
                            <el-tab-pane :key="tab.id" :name="tab.id + ''">
                                <div slot="label">{{tab.name}}<span class="tab-icon">{{tab.total}}</span></div>
                            </el-tab-pane>
                        </template>
                    </el-tabs>
                </div>
                <div class="mr20">
                    <tableIcon @change="tableModeChange"/>
                </div>
            </div>
        </div>
        <div class="my-comic-selectbox">
            <i class="el-icon-info color-green mr10"></i><span class="mr10">共 {{pagination.total}} 项</span><span class="mr10">已选择 <span class="color-green">{{selectComicList.length}}</span> 项</span>
            <el-button type="text" :disabled="selectComicList.length === tableList.length" @click="selectAllHandle">全选</el-button>
            <el-button type="text" :disabled="!selectComicList.length" @click="removeSelectHandle">清空</el-button>
        </div>
        <div v-show="!isTable" class="my-comic-table" ref="cardTable" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <comicCard ref="card" v-for="(card, index) in tableList" :key="card.comicId" :index="index" :showSelect="true" :list.sync="tableList" :selectComicList.sync="selectComicList" :comicData="formatPublicClass(card)"></comicCard>
            </template>
        </div>
        <el-table v-show="isTable" class="my-comic-table-list" ref="listTable" :data="tableList" border v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" style="width:auto" @selection-change="tableListSelect">
            <el-table-column type="selection" align="center" width="50"></el-table-column>
            <el-table-column prop="comicName" label="漫画名称" align="center"></el-table-column>
            <el-table-column label="审核状态" align="left" width="100">
                <template slot-scope="scope">
                    <div>{{scope.row.auditStatus | auditStatusFilter}}</div>
                    <el-tooltip v-if="[3, -1].includes(scope.row.auditStatus)" effect="light" placement="top">
                        <div slot="content">原因：{{scope.row.auditRemark}}</div>
                        <div class="color-red single-ellipsis">原因：{{scope.row.auditRemark}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="lastChapter" label="更新至" align="center">
                <template slot-scope="scope">{{scope.row.lastChapter | chapterNameFilter}}</template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center" width="180">
                <template slot-scope="scope">{{scope.row.createTime | timeFilter('yyyy年MM月dd日 hh:mm:ss')}}</template>
            </el-table-column>
            <el-table-column prop="description" label="操作" align="center" width="450">
                <template slot-scope="scope">
                    <el-popover placement="left-start" :title="scope.row.comicName" width="200" trigger="hover" content="">
                        <img style="width:100%" src="/static/space.gif" :key="$config.cdn + $utils.filterImagePath(scope.row.comicCover34)" v-lazy="$config.cdn + $utils.filterImagePath(scope.row.comicCover34)" alt="">
                        <el-button type="text" size="mini" slot="reference">查看漫画封面</el-button>
                    </el-popover>
                    <template v-if="scope.row.onlineShowStatus !==0">
                    <el-button type="text" size="mini" @click="$refs.card[scope.$index].editComicHandle(scope.row)">编辑漫画</el-button>
                    <el-button type="text" size="mini" @click="$refs.card[scope.$index].addChapter(formatPublicClass(scope.row))">新增章节</el-button>
                    <el-button type="text" size="mini" @click="$refs.card[scope.$index].managerChapter(formatPublicClass(scope.row))">管理章节</el-button>
                    <el-button v-if="[1,3,-1].includes(scope.row.auditStatus)" type="text" size="mini" @click="$refs.card[scope.$index].submitAudit(scope.row)">提交审核</el-button>
                    <el-button type="text" size="mini" @click="$refs.card[scope.$index].removeComicHandle(scope.row)">删除</el-button>
                    <!-- <el-button type="text" size="mini" @click="$refs.card[scope.$index].setLabelHandler(scope.row)">标签设置</el-button> -->
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSizes="[8, 20, 30, 40]" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <comic-publish
            @refreshData="refreshData"
            v-if="currentPublishComicData"
            :comicData.sync="currentPublishComicData"
            :platformList="platformList"
        ></comic-publish>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-12 17:33:59
 * @Description: 漫画管理-我的漫画
 * @Company: 成都二次元动漫
 */
import { searchMixin, comicMixin, paginationMixin } from '@/mixins';
import auditComicDialog from '@/views/main/adminCenter/auditComic/auditComicDialog';
import tableIcon from './components/tableIcon.vue';
import comicPublish from '@/components/comic/comicPublish';
const queryType = ['all', 'auditing', 'refuse', 'pass'];

export default {
    mixins: [searchMixin, comicMixin, paginationMixin],
    components: { auditComicDialog, tableIcon, comicPublish },
    data() {
        return {
            platformList: null, // 平台列表
            isloading: false,
            noData: true,
            pagination: {
                pageSize: 8
            },
            searchData: {
                queryType: queryType[0],
                queryText: null,
                showStatus: -2
            },
            auditType: [
                { id: 'all', name: '全部', total: null },
                { id: 'init', name: '待提交', total: null },
                { id: 'auditing', name: '审核中', total: null },
                { id: 'refuse', name: '未通过', total: null },
                { id: 'pass', name: '已通过', total: null }
            ],
            showTypes: [
                { id: -2, name: '全部' },
                { id: -1, name: '未上线' },
                { id: 0, name: '已下架' },
                { id: 1, name: '已上架' }
            ],
            tableList: [],
            isTable: false,
            currentPublishComicData: null, // 当前点击发布的章节信息
            selectComicList: [] // 选择的漫画
        };
    },
    computed: {
        currentTabId: {
            get() {
                return this.searchData.queryType + '';
            },
            set() {}
        }
    },
    watch: {
        selectComicList(newList) {
            if (newList.length === this.tableList.length) this.tableList.forEach(item => this.$refs.listTable.toggleRowSelection(item, true));
            else if (newList.length === 0) this.$refs.listTable.clearSelection();
            else {
                this.tableList.forEach(item => {
                    if (newList.includes(item)) this.$refs.listTable.toggleRowSelection(item, true);
                    else this.$refs.listTable.toggleRowSelection(item, false);
                });
            }
        }
    },
    created() {
        this.getComicPlatformList();
        this.getPublicClass().then(res => (this.publicClass = res));
        this.getTableList();
    },
    methods: {
        // 获取漫画平台列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => {
                this.platformList = data.map((platform) => {
                    return {
                        platformId: platform.id,
                        name: platform.name,
                        edit: false,
                        selectTypeId: null,
                        publishTime: null
                    };
                });
            });
        },
        // 刷新界面数据，用于改变发布信息后刷新
        refreshData(comicData) {
            this.getTableList().then(() => {
                const newComic = this.tableList.find(item => item.comicId === comicData.comicId);
                this.currentPublishComicData = newComic;
            });
        },
        // tab切换
        tabChangeHandler({ name }) {
            this.searchData.queryType = name;
            this.$nextTick(this.searchHandle);
        },
        // 列表模式切换
        tableModeChange({ isTable }) {
            this.isTable = isTable;
        },
        // 列表模式下的，选择
        tableListSelect(list) {
            if (list.length === 0) this.removeSelectHandle();
            else if (list.length === this.tableList.length) this.selectAllHandle();
            else this.selectComicList = list;
        },
        // 选择所有漫画
        selectAllHandle() {
            this.selectComicList = this.tableList;
        },
        // 清空所选漫画
        removeSelectHandle() {
            this.selectComicList = [];
        },
        // 漫画上/下架
        displayComicHandle(showStatus) {
            if (!this.selectComicList.length) return this.$message.info('请先选择漫画');
            const msg = showStatus === 0 ? '下架' : '上架';
            this.$utils.confirm('', `确定${msg}选中漫画吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayComic', { showStatus, comicIds: this.selectComicList.map(comic => comic.comicId) }).then(res => {
                    this.selectComicList = [];
                    this.getTableList();
                });
            });
        },
        // 展示发布dialog
        showDialog(comicData) {
            if (this.platformList === null || this.platformList.length === 0) {
                this.$message('平台列表尚未加载完成，请稍后点击');
                return;
            }
            this.currentPublishComicData = this.$utils.cloneDeep(comicData);
        },
        // 查看漫画详情
        previewComicInfo(comicData) {
            this.changeDialog(true, '', 0, { ...comicData, ...this.$utils.formatPublicClass(this.publicClass[0].child, comicData.classIdlist.split(',')) });
        },
        // 新增漫画
        addComicHandle() {
            this.$router.push({ name: 'comicInfo', query: { editType: 'add' } });
        },
        // 搜索
        searchHandle() {
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.queryType = null;
            this.searchHandle();
        },
        formatPublicClass(comicData) {
            const comicInfo = {
                ...comicData,
                ...this.$utils.formatPublicClass(this.publicClass[0].child, comicData.classIdlist.split(',')),
            }
            return comicInfo
        },

        // 查询列表
        getTableList() {
            this.isloading = true;
            return this.$api('getComicList', { ...this.searchData, ...this.pagination }).then(res => {
                this.$refs.cardTable.scrollTop && (this.$refs.cardTable.scrollTop = 0);
                const { rows, pageIndex, pageSize, total, statusData } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination = {
                    ...this.pagination,
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    total
                };
                this.auditType = this.auditType.map(type => {
                    if (type.id === null) {
                        type.total = Object.values(statusData).reduce((s, n) => {
                            s += n;
                            return s;
                        }, 0);
                    } else type.total = statusData[type.id];
                    return type;
                });
                this.isloading = false;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>

<style lang="scss">
    .my-comic{
        .search-box{
            flex-basis: 50px;
        }
        &.page-container{
            padding: 20px 0 0;
        }
        .search-input{
            width: 200px;
        }
        &-operation{
            padding: 0 10px;
            flex-basis: 54px;
            .tab-icon{
                margin-left: 5px;
            }
        }
        &-table{
            display: flex;
            flex-grow: 1;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 10px 0  10px 20px;
            position: relative;
            overflow-y: auto;
            &-list{
                flex-grow: 1;
                margin: 10px 20px;
                .el-table__body-wrapper{
                    height: calc(100% - 40px);
                    position: relative;
                    overflow: auto;
                }
            }
        }
        &-publish{
            padding: 20px;
            .el-checkbox-group{
                flex-flow: row wrap;
                .el-checkbox{
                    min-width: 100px;
                }
            }
            .el-checkbox{
                margin: 0 30px 10px 0;
            }
        }
        &-selectbox{
            flex-basis: 34px;
            padding: 0 10px;
            margin: 0 20px 10px;
            border-radius: 4px;
            background-color: rgba(230, 247, 255, 1);
            border: 1px solid rgba(186, 231, 255, 1);
        }
    }
    .el-message-box__title{
        span{
            font-size: 16px;
        }
    }
    label.platform-checkbox{
        margin-left: 0;
        margin-right: 30px;
    }
</style>
